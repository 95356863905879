.Accordion {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.Accordion-panels {
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1 1 auto;
}

.Accordion-borderless-panels {
    border: none !important;
}
.Accordion-panel-heading {
    padding: 3px !important;
    background-color: #f9f9f9a1 !important;
}

.Accordion-panel-heading-wrapper {
    border-radius: unset !important;
    border-right: none !important;
    border-left: none !important;
}

.Accordion-Collapse-collapse {
    /*
    overflow-y: scroll !important;
    overflow-x: hidden !important;
     */
}

.Accordion-innerAccordion {
    height: auto !important;
}

.Accordion-Collapse-content {
}

.Accordion-mainAccordion {
    margin: 10px !important;
    padding: 0 !important;

}

.panel-body.fillAllHeight {
    height: calc(100% - 15px);
}

.panel-body {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: scroll;
    border-radius: 10px;
    padding: 15px 15px 0 10px;
    margin: 10px 10px 0 10px;
    box-shadow: rgb(90 114 123 / 11%) 0 7px 30px 0;
}
