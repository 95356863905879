.DataTableBoardButton {
    height: 35px;
    width: 35px;
    margin: 0 3px 0 3px;
    color: #5b5965;
    bottom: 0;
    top: auto;
    outline: none !important;
    background-color: #fffafa !important;
    border-radius:25px !important;
    margin-left:5px !important;
    padding: 8px !important;
}