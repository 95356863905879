.List-ListPanel {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.List-ErrorPanel {
    padding: 20px;
    font-size: 16px;
}

.List-ErrorPanel button{
    margin-top: 10px;
    font-size: 16px;
}