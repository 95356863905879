.GraphBoard {
    display: flex;
    justify-content: space-between;
}

/*leftBoard Style*/
.GraphBoard-left {
    display: flex;
}
/*rightBoard Style*/
.GraphBoard-right {
    display: flex;
}

.GraphBoard-select {
    margin-right: 20px;
}