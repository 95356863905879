.KpDashboard-container {
    padding: 1% 3% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    height: 100%;
    overflow-y: auto;
}

.videoContainer {
    padding: 10px 10px !important;
}

.KpDashboard-card {
    height: 100%;
    margin: 20px 0;
}

.KpDashboard-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.45);

}

.mediaLinksGroup {
    position: sticky;
    top: -15px;
    text-align: left;
}

.mediaLinksGroup-title {
    padding: 14px;
    font-size: 18px;
    color: #e94e24de;
}

.Article-tag {
    padding: 1px 4px;
    font-weight: 500;
    font-size: 12px;
}

.MediaContainer {
    box-shadow: rgb(0 0 0 / 2%) 0 0.625rem 0.9375rem -0.1875rem, rgb(0 0 0 / 5%) 0 0.25rem 0.375rem -0.125rem;
    padding: 5px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255) !important;
}

.mediaLink {
    display: inline-flex;
    align-items: flex-end;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    color: #214d85;
}

.mediaLink:hover {
    background-color: rgb(240, 242, 245);
    cursor: pointer;
}

.Article-container {
    margin: auto;
    max-width: 700px;
}

.Article-title {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 15px 15px;
    letter-spacing: -0.011em;
    line-height: 32px;
    cursor: pointer;
}

.Article-title:hover {
    text-decoration: underline;
}

.Article-image {
    margin: 20px 15px;
}

.Article-text {
    margin: 5px 10px;
    font-size: 14px;
    letter-spacing: 0.03em;
}

.Article-separator {
    border: none;
    border-top: 2px solid #bbb7b5;
    text-align: center;
    height: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
}
