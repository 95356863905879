.FilterForm-form {
    display:flex;
    flex-wrap: wrap;
    margin-top:5px;
}
.FilterForm-hr {
    margin:10px;
}


.FilterForm-button {
    align-self: center;
    border: 0 !important;
    margin: 2px 5px 0;
    height: 35px;
    width: 168px;
}
