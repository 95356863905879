.QuillTextEditor-label {
    margin-bottom: 2px;
    padding: 0 5px;
    color: #686972;
    margin-left: 14px;
    font-weight: 400;
    font-size: 12px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ql-container {
    min-height: 10em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border: 1px solid #ccc;
    border-top: 0 !important;;
}

.ql-container img {
    max-width: 100%;
    height: auto;
}

.ql-toolbar {
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

#toolbar {
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border: 1px solid #ccc;
    padding: 6px;
}

#toolbar .sketch-picker {
    left: 290px;
    margin-top: -296px;
}

.ql-editor strong{
    font-family: sans-serif;
    font-weight:bold !important;
}

.ql-editor {
    font-family: sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before
{
    font-family: "Arial", cursive;
    content: "Arial";
}
.ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before
{
    font-family: "Georgia", cursive;
    content: "Georgia";
}
.ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before
{
    font-family: "Helvetica", cursive;
    content: "Helvetica";
}
.ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before
{
    font-family: "Tahoma", cursive;
    content: "Tahoma";
}

.ql-picker.ql-font .ql-picker-label[data-value="Times"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Times"]::before
{
    font-family: "Times", cursive;
    content: "Times";
}

.ql-picker.ql-font .ql-picker-label[data-value="Trebuchet"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Trebuchet"]::before
{
    font-family: "Trebuchet MS", cursive;
    content: "Trebuchet MS";
}

.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before
{
    font-family: "Verdana", cursive;
    content: "Verdana";
}



.ql-font-Arial {
    font-family: "arial", sans-serif;
}

strong.ql-font-Arial {
    font-family: "arialbd", sans-serif;
}

.ql-font-Georgia {
    font-family: "georgia", serif;
}

strong.ql-font-Georgia {
    font-family: "georgiabd", serif;
}

.ql-font-Helvetica {
    font-family: "Helvetica", sans-serif;
}

strong.ql-font-Helvetica {
    font-family: "Helveticabd", sans-serif;
}

.ql-font-Tahoma {
    font-family: "tahoma", sans-serif;
}

strong.ql-font-Tahoma {
    font-family: "tahomabd", sans-serif;
}


.ql-font-Times {
    font-family: "times", sans-serif;
}

strong.ql-font-Times {
    font-family: "timesbd", sans-serif;
}

.ql-font-Trebuchet {
    font-family: "trebuc", sans-serif;
}

strong.ql-font-Trebuchet {
    font-family: "trebucbd", sans-serif;
}

.ql-font-Verdana {
    font-family: "verdana", sans-serif;
}

strong.ql-font-Verdana {
    font-family: "verdanabd", sans-serif;
}

/* Base table styles */
.custom-table {
    margin: 1rem 0;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

/* Table cells */
.custom-table td {
    word-break: break-word; /* Ensures long words or URLs break to the next line */
    overflow-wrap: break-word; /* Adds compatibility for older browsers */
    white-space: normal; /* Ensures text wraps onto the next line */
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    position: relative;
    transition: background-color 0.2s ease;
    min-width: 40px;;
}

/* Editable cell focus state */
.custom-table td[contenteditable="true"]:focus {
    outline: 1px solid rgba(0, 0, 0, 0.05);
    outline-offset: -2px;
    background-color: rgba(0, 0, 0, 0.05);
}

/* Hover state for cells */
.custom-table td:hover {
    background-color: rgba(0, 0, 0, 0.05)
}

/* Zebra striping for better readability */
.custom-table tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02)
}

/* Selected cell state */
.custom-table td.selected {
    background-color: #ebf8ff;
    border-color: #4299e1;
}

/* Responsive design */
@media (max-width: 768px) {
    .custom-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .custom-table td,
    .custom-table th {
        padding: 8px 12px;
    }
}

/* Print styles */
@media print {
    .custom-table {
        box-shadow: none;
        border: 1px solid #000;
    }

    .custom-table td,
    .custom-table th {
        border: 1px solid #000;
    }
}

/* Disabled state */
.custom-table[disabled] td {
    background-color: #f7fafc;
    color: #a0aec0;
    cursor: not-allowed;
}

/* Focus visible styles for accessibility */
.custom-table td:focus-visible {
    outline: 2px solid #4299e1;
    outline-offset: -2px;
}

.table-selector-popup {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.table-selector-header {
    font-size: 14px;
    margin-bottom: 12px;
    padding: 0 6px;
    color: #333;
}

.table-selector-grid {
    display: grid;
    grid-template-columns: repeat(8, 24px);
    gap: 2px;
    padding: 4px;
    width: fit-content;
}

.table-selector-cell {
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.15s ease;
}

.table-selector-cell-selected {
    background-color: #BBDEFB;
    border-color: #90CAF9;
}

.table-selector-cell:hover {
    border-color: #2196F3;
}

.table-selector-close {
    position: absolute;
    top: 8px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    color: #666;
    cursor: pointer;
    padding: 4px 8px;
}

.table-selector-close:hover {
    color: #333;
}

.table-context-menu {
    user-select: none;
}

.context-menu-item {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.context-menu-item:hover {
    background-color: #f0f0f0;
}

.context-menu-icon {
    font-size: 16px;
}

.table-align-left {
    /*margin-left: 0; margin-right: auto;
    float: left;
    margin: 0 1em 1em 0;*/
    float: none;
    margin-right: auto;
    margin-left: 0;
}

.table-align-center {
    /*margin-left: auto;
    margin-right: auto;*/
    margin-left: auto;
    margin-right: auto;
}

.table-align-right {
    /*margin-left: auto; margin-right: 0;
    float: right;
    margin: 0 0 1em 1em;*/
    float: none;
    margin-right: 0;
    margin-left: auto;
}

.table-align-right-with-text{
    float: right;
    margin-left: 6px;
}
.table-align-left-with-text{
    float: left;
    margin-right: 20px;
}

.table-context-menu ul {
    position: relative;
    padding: 0;
}

.table-context-menu .submenu {
    position: absolute;
    top: 0;
    /*left: 100%;*/
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

.context-menu-item {
    padding: 8px 10px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap; /* Prevents text wrapping */
    gap: 10px
}

.rightIcons{
    margin-left: auto;
    margin-right: 0;}

.context-menu-item:hover {
    background-color: #f5f5f5;
}

.context-menu-divider {
    border-top: 1px solid #eee;
    margin: 4px 0;
}

.image-context-menu .context-menu-item {
    padding: 8px 10px 8px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
}

.image-context-menu .context-menu-item:hover {
    background-color: #f5f5f5;
}

.image-context-menu .submenu {
    position: absolute;
    /*left: 100%;*/
    top: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.image-context-menu .rightIcons {
    margin-left: auto;
}

/*.image-container{
    display : grid;
    grid-template-columns : 1fr 1fr 1fr;
    gap : 1em;
    grid-column : 2;
}*/

.image-container {
    position: relative;
    margin-bottom: 1em;
    margin-top: 1em;
    display: block; /* Ensures the container behaves like a block-level element */
    width: 100%; /* Makes the container span the full width of its parent */
    max-width: 100%;
    clear: both; /* Ensures no floating elements overlap with this container */
}


.image-container img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.align-left {
    display: block;
    float: none;
    margin-right: auto;
    margin-left: 0;
}

.align-right {
    display: block;
    float: none;
    margin-right: 0;
    margin-left: auto;
}

.align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.align-left-with-text {
    display: inline;
    float: left;
    margin-right: 20px;
    margin-left: 0;
    margin-top: 18.5px;
}

.align-right-with-text {
    display: inline;
    float: right;
    margin-right: 0;
    margin-left: 6px;
    margin-top: 18.5px;
}

.align-center-with-text {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap; /* Allows text and image to wrap onto the next line if needed */
    float: none;
    margin: 0 10px;
}

.image-left-text,
.image-right-text {
    flex: 0 1 auto;
    /*min-width: 100px;*/
    /*max-width: 100%;*/
    white-space: pre-wrap;
    overflow-wrap: break-word; /* Breaks words to prevent overflow */
    word-wrap: break-word; /* Fallback for older browsers */

}

.image-container .image-left-text,
.image-container .image-right-text {
    display: inline-block;
    min-width: 100px;
    vertical-align: top;
}


.image-remove-module {
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    background: white;
    border: 1px solid #000;
    border-radius: 50%;
    padding: 2px;
}