.LabeledCheckboxField {
    padding: 0 5px
}
.LabeledCheckboxField-input {
    color: rgb(30 30 30);
}

.LabeledCheckboxField-label {
    font-size: 15px;
}

.LabeledCheckboxField-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}

.LabeledCheckboxField-container {
    margin: 0;
    cursor: pointer;
    align-items: center;
}


.LabeledCheckboxField-container:after {
    content: '';
    display: table;
    clear: both;
}

.LabeledCheckboxField .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.2em;
    height: 1.2em;
    float: left;
}

.LabeledCheckboxField .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 12%;
    color: #607d8b;
}

.LabeledCheckboxField .LabeledCheckboxField-container input[type="checkbox"] {
    display: none;
}

.LabeledCheckboxField .LabeledCheckboxField-container input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
}

.LabeledCheckboxField .LabeledCheckboxField-container input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.LabeledCheckboxField .LabeledCheckboxField-container input[type="checkbox"]:disabled+.cr {
    opacity: .5;
}
