.Menu-container {
    opacity: 0;
    position: absolute;
    top: 100%;
    z-index: 1000;
    font-size: 0.875rem;
    text-align: left;
    box-shadow: rgb(0 0 0 / 10%) 0 0.625rem 0.9375rem -0.1875rem, rgb(0 0 0 / 5%) 0 0.25rem 0.375rem -0.125rem;
    padding: 0;
    border-radius: 8px;
    background-color: rgb(255, 255, 255) !important;
}

.Menu-container.left {
    right: 0;
}

.Menu-container.right {
    left: 0;
}

.Menu-container.opened {
    display: block;
    padding: 0.5rem;
    transform: none;
    opacity: 1;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 174ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    animation: growOut 280ms ease-in-out forwards;
}

.Menu-container.left.opened {
    transform-origin: 190px 0;
}

.Menu-container.right.opened {
    transform-origin: 0 0;

}

@keyframes growOut {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}
