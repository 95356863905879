.RichTextEditor {
    position: relative;
    padding: 5px;
}

.RichTextEditor-label {
    position: absolute;
    z-index: 1;
    top: -3px;
    background-color: white;
    padding: 0 5px;
    color: #686972;
    margin-left: 14px;
    font-weight: 400;
    font-size: 12px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.toolbar-menu {
    position: absolute;
    bottom: 0;
    z-index: 0;
    padding-left: 10px;
    padding-bottom: 5px;
}

.disabled {
    z-index: -2 !important;
}

.button {
    color: #a8a8a8;
    cursor: pointer;
}

.button[data-active='true'] {
    color: #0a8591;
}

/*#00664a*/
.editor {
    padding: 12px 6px 40px 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.editor.focus {
    border-color: #015ECC !important;
    border-width: 2px;
}

.editor.applyMinHeight {
    min-height: 200px;
}

.editor.readOnly {
    border: none !important;
    padding: 0 !important;
}

.editor:hover {
    border-color: rgb(0 0 0 / 50%);
}

.menu > * + * {
    margin-left: 15px;
}


.menu > * {
    display: inline-block;
}


/*@font-face {*/
    /*font-family: 'Material Icons';*/
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*src: url(https://fonts.gstatic.com/s/materialicons/v38/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');*/
/*}*/

.material-icons {
    font-size: 20px;
    /*font-family: 'Material Icons';*/
    /*font-weight: normal;*/
    /*font-style: normal;*/
    /*vertical-align: text-bottom;*/
    /*line-height: 1;*/
    /*letter-spacing: normal;*/
    /*text-transform: none;*/
    /*display: inline-block;*/
    /*white-space: nowrap;*/
    /*word-wrap: normal;*/
    /*direction: ltr;*/
    /*font-feature-settings: 'liga';*/
    /*-webkit-font-feature-settings: 'liga';*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*text-rendering: optimizeLegibility;*/
}


.RichTextEditor pre {
    padding: 10px;
    background-color: #eee;
    white-space: pre-wrap;
}

.RichTextEditor :not(pre) > code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
}


.RichTextEditor blockquote {
    border-left: 2px solid #ddd;
    color: #aaa;
    font-style: italic;
    padding: 0 10px 0 0;
    margin: 10px 0 10px 0;
    font-size: 14px;
    border-left: 2px solid #ddd;
}

/*.RichTextEditor blockquote[dir='rtl'] {*/
    /*border-left: none;*/
    /*padding-left: 0;*/
    /*padding-right: 10px;*/
    /*border-right: 2px solid #ddd;*/

/*}*/


.check-list-item + .check-list-item {
    margin-top: 0;
}

.check-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.check-list-item.checked {
    opacity: 0.666;
    text-decoration: line-through;
}

.check-list-item > span:first-child {
    margin-right: 0.75em;
}

.check-list-item > span:last-child {
    flex: 1;
}

.check-list-item > span:last-child:focus {
    outline: none;
}

.word-counter {
    margin-top: 10px;
    padding: 12px;
    background-color: #ebebeb;
    display: inline-block;
}
