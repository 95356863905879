@font-face {
    font-family: 'arial';
    src: local('arial'), url(./fonts/arial.ttf) format('truetype');
}

@font-face {
    font-family: 'arialbd';
    src: local('arialbd'), url(./fonts/arialbd.ttf) format('truetype');
}

@font-face {
    font-family: 'georgia';
    src: local('georgia'), url(./fonts/georgia.ttf) format('truetype');
}

@font-face {
    font-family: 'georgiabd';
    src: local('georgiabd'), url(./fonts/georgiabd.ttf) format('truetype');
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(./fonts/Helvetica.ttf) format('truetype');
}

@font-face {
    font-family: 'Helveticabd';
    src: local('Helveticabd'), url(./fonts/Helveticabd.ttf) format('truetype');
}

@font-face {
    font-family: 'tahoma';
    src: local('tahoma'), url(./fonts/tahoma.ttf) format('truetype');
}

@font-face {
    font-family: 'tahomabd';
    src: local('tahomabd'), url(./fonts/tahomabd.ttf) format('truetype');
}

@font-face {
    font-family: 'times';
    src: local('times'), url(./fonts/times.ttf) format('truetype');
}

@font-face {
    font-family: 'timesbd';
    src: local('timesbd'), url(./fonts/timesbd.ttf) format('truetype');
}

@font-face {
    font-family: 'trebuc';
    src: local('trebuc'), url(./fonts/trebuc.ttf) format('truetype');
}

@font-face {
    font-family: 'trebucbd';
    src: local('trebucbd'), url(./fonts/trebucbd.ttf) format('truetype');
}

@font-face {
    font-family: 'verdana';
    src: local('verdana'), url(./fonts/verdana.ttf) format('truetype');
}

@font-face {
    font-family: 'verdanabd';
    src: local('verdanabd'), url(./fonts/verdanabd.ttf) format('truetype');
}
