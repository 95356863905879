.Registration-container {
    background-color: rgb(54, 22, 51);
    position: absolute;
    height: 100%;
    width: 100%;
}

.Registration-form-container {
    max-width: 720px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Registration-well {
    background-color: #fefefe !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 40px 40px;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.Registration-Form {
    overflow-y: scroll;
}

.Registration-from-title {
    text-align: center;
    font-size: large;
}

.Registration-links-container {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    margin: 10px 0 10px;
}

.Registration-link {
    color: darkslategrey;
}

.Registration-mainButton {
    height: 40px;
    width: fit-content;
    align-self: center;
    color: snow;
    background-color: #337ab7;
    transition: background-color 300ms ease;
}

.Registration-mainButton:hover {
    color: snow !important;
    background-color: #265d8a;
    transition: background-color 300ms ease;
    border-color: #1b4364;
}

.Registration-mainButton:focus {
    color: snow !important;
}

.section-title {
    margin: 15px 7px 15px;
    font-size: larger;
    color: rgb(252, 185, 0);
    padding: 10px 0;
}

.Select-label {
    top: -7px;
}
