body, html {
    height: 100%;
    overflow: hidden;
}

#root {
    height: 100%;
}

.Select-menu-outer{
    position: absolute !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* setting font-size to 16px to prevent ios from zooming */

@media only screen and (max-device-width: 600px) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 16px;
    }

    .panel-body {
        padding: 15px 5px 0 5px !important;
    }
}

.form-control {
    height: 38px;
    border: 1px solid #a1a1a1;
    border-radius: 3px;
}
.form-control[disabled]{
    background-color: #f9f9f9;
}
.form-control[readonly]{
    background-color: #eeeeee66;
}

.ReactVirtualized__Table__headerRow {
    border: none !important;
    color: #686972 !important;
    box-shadow: unset !important;
}

/*
#custom-toastr-style .rrt-confirm {
    width: 400px;
    background-color: white;
    text-align: start;
    border-radius: 4px;
    overflow: hidden;
}
*/

#custom-toastr-style .rrt-message {
    text-align: start;
}

.black-toastr-style{
    background: #252424 !important;
}


.black-toastr-style .rrt-progressbar {
    background: white !important;
}


.Main-navbar {
    background-color: transparent;
}


.GroupModel-category-icon .opened {
    color: #ad4e34;
}

.technicalMenu a:hover, .technicalMenu p:hover {
    background-color: rgb(240, 242, 245);
    color: #353535;
    text-decoration: none;
}
.technicalMenu a:focus, .technicalMenu p:focus {
    background-color: rgb(240, 242, 245);
    color: #353535;
    text-decoration: none;
}

.technicalMenu a:active, .technicalMenu p:active {
    color: #353535;
    text-decoration: none;
}



