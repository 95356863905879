.Login-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgb(246 249 252);
}

.Login-container .Checkbox-label {
    font-weight: 400;
}

.Login-form-container {
    max-width: 520px;
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login-navbar {
    border-radius: 0 !important;
    min-height: 37px !important;
}
.Login-well {
    width: 100%;
    min-height: 400px;
    text-align: center;
    background-color: #fefefe !important;
    padding: 40px;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.Login-form-title {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-align: center;
}

.Login-form-subtitle {
    margin: 0 0 10px;
    font-size: 13px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
}


.Login-form-input-group {
    position: relative;
    padding: 5px 5px;
}

.Login-form-input-label {
    background-color: white;
    position: absolute;
    font-weight: 400;
    padding: 0 5px;
    top: 20px;
    left: 15px;
    font-size: 14px;
}

.Login-form-input {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 15px;
}

.Login-form-input:focus-visible > .Login-form-input-label {
    top: -2px;
    left: 15px;
    font-size: 10px;
}

.Login-logo-container {
    margin-bottom: 20px;
}

.Login-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Login-confirmation-code {
    display: flex;
    width: 220px;
    justify-content: space-between;
    flex-direction: row;
}

.Login-confirmation-text {
    padding: 15px;
    text-align: center;
    margin-bottom: 20px;
}

.Login-confirmation-code-input {
    width: 30px;
    font-size: 20px;
    font-weight: 500;
    padding-left: 7px;
}

.Login-kpLogo {
    padding-left: 40px;
}
.Login-kpLogo-img {
    width: auto;
    height: 20px;
}

.Login-text {
    margin-top: 5px;
    margin-bottom: 20px;
}

.Login_passwordLink {
    color: #3a3a3a;
    cursor: pointer;
    padding-top: 7px;
}

.Login_passwordLink:hover {
    color: #275e8f;
    text-decoration: none;
}

.Login-eye-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    padding: 5px;
    cursor: pointer;
    margin-left: -30px;
    margin-top: 3px;
}

.remove-button-outline {
    outline: none !important;
}

.remove-button-outline:focus {
    box-shadow: none !important;
    outline: none !important;
}

.remove-button-outline:active {
    box-shadow: inset 0 0 5px #8080805c !important;
}

.Login-mainButton {
    color: snow;
    width: 100%;
    background-color: #337ab7;
    transition: background-color 300ms ease;
}

.Login-mainButton:hover {
    color: snow !important;
    background-color: #265d8a;
    transition: background-color 300ms ease;
    border-color: #1b4364;
}

.Login-mainButton:focus {
    color: snow !important;
}


.Reset-pass-buttons {
    display: flex;
    flex-direction: column;
}

.Reset-pass-submit-button {
    margin-bottom: 10px !important;
    color: snow;
    width: 100%;
    background-color: #337ab7;
    transition: background-color 300ms ease;
}


.Reset-pass-cancel-button:hover {
    background-color: #f1f1f1 !important;
    border-color: #d2d2d2 !important;
}

.Reset-pass-submit-button:hover {
    color: snow !important;
    background-color: #265d8a;
    transition: background-color 300ms ease;
    border-color: #1b4364;
}
