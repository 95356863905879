.Files-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.ellipsis {
    overflow: hidden;
    width: 60px;
    direction: rtl;
    margin-left: 15px;
    white-space: nowrap;
}

.ellipsis:after {
    position: absolute;
    left: 0;
    content: "...";
}

.align-horizontally {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Images-Files-container {
    display: flex;
    flex-direction: column;
}

.Files-panel-body {
    margin-top: 7px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.Files-input-group {
    width: 100%;
    height: 50px;
    overflow: hidden;
    position: relative;
}

.Files-button {
    background-color: #e7f5e7;
    font-weight: 300 !important;
    font-size: 15px !important;
    border-style: dashed !important;
    border-width: 1px !important;
    border-color: #c1c3c5 !important;
    color: #686972;
    display: inline-block;
    padding: 0 10px;
    overflow: hidden;
    width: 100%;
    height: 50px;
}

.Files-button.hovered {
    background-color: #dff0d8;
}

.Files-button-Text {
    vertical-align: middle;
}

.Files-Icon {
    vertical-align: middle;
    margin-right: 8px;
}

.Files-input {
    font-size: 200px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.Files-header {
    color:#3c3c41;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    text-transform: capitalize;
}

.Files-grid {
    outline: none
}

.Files-row {
    color:#686972;
    font-weight: 300;
    cursor: pointer;
    border-bottom: 2px solid #f2f2f2;
    outline: none;
}

.Files-row.odd {
    background-color:#f9f9f9;
}

svg {
    cursor: pointer;
}

.Files-container > .ProgressBar {
    height: 12px;
    width: 150px;
}
.uploadProgress {
    text-align: center;
    margin: 40px auto 20px;
}

.imgs {
    border: 1px solid #cccccc;
    border-radius: 6px;
    margin-bottom: 5px;
}
