.AccordionForm {
    margin: 10px !important;
    height: calc(100% - 15px);
}

.AccordionForm-HideScroll {
    overflow-y: scroll;
}

.AccordionForm::-webkit-scrollbar {
    display: none;
}

.AccordionForm {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
