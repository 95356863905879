.AccordionComponent-form {
    padding: 15px;
}

.AccordionComponent-listNewButton {
    font-size: 23px ;
    position: absolute !important;
    float: right;
    top: 3px !important;
    right: 5px;
    padding: 0;
    cursor: pointer;
    color: #555;
}


.AccordionComponent-label {
    position: absolute;
    color: #686972;
    z-index: 1;
    padding: 0 5px;
    background-color: white;
    font-weight: 400;
    margin-left: 10px;
    top: -3px;
    font-size: 11px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
