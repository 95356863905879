.Action-menu {
    padding: 0 2px;
}

.Action-menu a {
    padding: 10px 20px !important;
    cursor: pointer;
    min-height: 35px;
}

.Action-hr {
    margin-top: 0px;
    margin-bottom: 1px;
}

.ActionSelector-dropDown {
    min-width: 220px !important;
    padding: 0 5px !important;
}
