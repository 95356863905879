.DonutCard-container {
    padding: 0 !important;
}

.DonutCard {
    height: 450px;
    margin: 10px 10px;
    padding: 5px 10px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
}

.DonutCard:hover {
    box-shadow: 0 5px 9px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.DonutCard h4 {
    text-align: center;
    font-family: "frutiger-light",sans-serif !important;
}

.DonutCard-content {
    height: 100%;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    font-size: inherit;
}

.DonutCard-link {
    height: 30px;
    position: relative;
    padding: 5px 10px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: right;

}

.DonutCard-link:hover {
    text-decoration: none !important;

}

.DonutCard-icon {
    position: absolute;
    top: 3px;
}