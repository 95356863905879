.RankOptionsField-options-container {
    display: flex;
    flex-direction: column;
    margin: 15px 0 0 20px;
}

.RankOptionsField-option-container {
    position: relative;
    display: flex;
    flex-direction: row;
    background: white;
    width: 45%;
    border: 1px solid grey;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px 5px;
    font-weight: 400;
    cursor: grab;
}

.RankOptionsField-label {
    font-size: 15px;
}

.RankOptionsField-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}

.RankOptionsField-option-icon {
    align-self: center;
    margin: 1px 8px 1px 4px;
}

.RankOptionsField-option-rank {
    text-align: center;
    right: 0;
    width: 20px;
    font-weight: 500;
    padding: 2px;
    position: absolute;
    margin-right: 4px;
    height: 20px;
    color: #4e8fdc;
}

.RankOptionsField-option-label:active {
    cursor: grabbing !important;
    visibility: visible !important;
}
