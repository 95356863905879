.Banner-container {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    background-image: none;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    font-size: 13px;
    line-height: 1.43;
    display: flex;
    padding: 6px 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: center;
}

.Banner-icon-container {
    margin-right: 12px;
    padding: 7px 0;
    display: flex;
    font-size: 22px;
    opacity: 0.9;
}

.Banner-message {
    padding: 8px 0;
}
