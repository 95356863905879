.CheckboxField {
    padding: 0 5px
}
.CheckboxField-input {
    color: rgb(30 30 30);
}

.CheckboxField-label {
    padding-left: 10px;
    color: rgb(30 30 30);
    font-weight: 400;
}

.CheckboxField label {
    margin: 0;
    align-items: center;
}

.CheckboxField label:after {
    content: '';
    display: table;
    clear: both;
}

.CheckboxField .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.2em;
    height: 1.2em;
    float: left;
    cursor: pointer
}

.CheckboxField .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 12%;
    color: rgb(30 30 30);
}

.CheckboxField label input[type="checkbox"] {
    display: none;
}

.CheckboxField label input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
}

.CheckboxField label input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.CheckboxField label input[type="checkbox"]:disabled+.cr {
    opacity: .5;
}
