/* this class applies to all rows, including the heading */
.DataTable-Table-row {
    color:#686972;
    font-weight:normal;
    border-bottom: 1px solid #f2f2f2;
    outline: none;
    cursor: default;
}

.DataTable-Table-row-column {
    margin-left: 10px;
}

.AccordionRow {
    color: rgb(72 72 72);
}

.DataTable-Table-row:hover {
    background-color: #f8f7f7;
}
.DataTable-Table-row.first:hover {
    /*
    border-top: none;
    z-index: 1;
     */
}

.DataTable-Table-row.editable {
    cursor: pointer;
}

.DataTable-Table-row.disabled {
    cursor: not-allowed;
}

.DataTable-Table-row.greenStyledRow {
    background-color: rgba(101, 201, 103, 0.41);
}

.DataTable-Table-row.orangeStyledRow {
    background-color: #ffd198;
}

.DataTable-Table-row.redStyledRow {
    background-color: #ffaaaa;
}

.DataTable-Table-row.orangeStyledRow:hover {
    color: #686972;
}

.DataTable-Table-row.greenStyledRow:hover {
    color: #686972;
}

/* each header inside the header row */
.DataTable-Table-header {
    position: relative;
    padding: 10px 10px 5px 10px;
    margin: 0 !important;
    font-size: 14px;
    text-transform: none;
    outline: none;
    display: flex;
    align-items: center;
}

.AccordionHeader {
    font-size: 13px;
}

.DataTable-Table-header:hover {
    background-color: #f8f7f7;
}

.DataTable-Table-row.header {
    align-items: end;
    overflow: visible;
}

.AccordionHeaderRow.header {
    background-color: white;
}

.AccordionHeaderRow.header:hover {
    background-color: white !important;
}

.DataTable-Table-row.header:hover {
    background-color: transparent;
}

.breakHeaderLine span {
    white-space: normal !important;
    word-spacing: 1000px
}

.DataTable-Table-grid {
    border-top: 1px solid #adb0ba;
    outline: none;
}

.DataTable-Table-noRows {
    color: #686972;
    height: 40px;
    line-height: 40px;
    background-color: #f9f9f9;
    padding-left: 10px;
}

.redAlert {
    background-color: #e65353 !important;
    color: rgba(255, 250, 250, 0.9);
}

.redAlert:hover {
    color: rgba(255, 250, 250, 0.9);
}

.orangeAlert {
    background-color: rgba(231, 98, 31, 0.7) !important;
    color: rgba(255, 250, 250, 0.9);
}

.orangeAlert:hover {
    color: rgba(255, 250, 250, 0.9);
}

.blueAlert {
    background-color: #1b638e !important;
    color: rgba(255, 250, 250, 0.9);
}

.blueAlert:hover {
    color: rgba(255, 250, 250, 0.9);
}

.RowColumn {
    margin: 0 !important;
    padding: 0 10px;
}

.RowColumn-trashCell {
    margin: 0 !important;
    padding: 0 !important;
}

.RowColumn-checkbox {
    margin: 0 !important;
    padding: 0 !important;
}

.noHeaderDisplay {
    opacity: 0;
}
