.StatusTag-container {
    display: block;
    padding: 1px 8px;
    width: fit-content;
    border-radius: 25px;
    text-align: center;
    color: white;
}

.StatusTag-container.empty {
    display: none;
}

.StatusTag-container.default {
    background: lightgray;
}

.StatusTag-container.primary {
    background: #449bf1;
}

.StatusTag-container.success {
    background: #249b24;
}

.StatusTag-container.warning {
    background: #f09d04;
}

.StatusTag-container.danger {
    background: #f86262;
}

