.Comment-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.Comment-panel-body {
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.Comment-comment{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.Comment-header {
    color:#3c3c41;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    text-transform: capitalize;
}
.Comment-textarea {
    outline: none !important;
    width: 80%;
}
.Comment-grid {
    outline: none
}

.Comment-row {
    color:#686972;
    font-weight: 300;
    cursor: pointer;
    border-bottom: 2px solid #f2f2f2;
    outline: none;
}

.Comment-row.odd {
    background-color:#f9f9f9;
}

.Comment-focus {
    border-color: #e48b85 !important;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7%), 0 0 3px rgb(183 137 134 / 91%) !important;
    background-color: #fff !important;
}

.Comment-button {
    margin-left: 10px;
    background-color: #fff !important;
    outline: none !important;
}
