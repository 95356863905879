.TimePicker-wrapper{
    display: flex;
    flex-direction: column;
}

.rc-time-picker-input {
    height: 34px;
    padding: 6px 12px;
    outline: none;
    font-size: 14px;
    color: #555;
    border: 1px solid #ccc;
}

.rc-time-picker-panel-input {
    font-size: 13px;
}