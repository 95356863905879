.DynamicStyledInput-group {
    position: relative;
    padding: 5px 5px;
}

.DynamicStyledInput-input {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 15px;
}

.DynamicStyledInput-label {
    background-color: white;
    position: absolute;
    font-weight: 400;
    padding: 0 5px;
    top: 20px;
    left: 15px;
    font-size: 13px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: all 200ms;
}

.DynamicStyledInput-label.hasFocus {
    top: -1px;
    font-size: 11px;
    transition: all 200ms;
}

.DynamicStyledInput-input:hover {
    border-color: rgb(0 0 0 / 50%);
}

.DynamicStyledInput-input.hovered {
    border-color: rgb(0 0 0 / 50%);
}

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

input:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
}
