.Module-container {
    margin: 0;
    padding: 6px;
    height: 100%;
}

.Module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Module-title-container {
    overflow: hidden;
    margin: 0 15px;
    padding-right: 10px;
    display: inline-flex;
    align-items: center;
}


.Module-menu-title {
    color: grey;
    line-height: 37px;
    margin: 2px 2px 2px 10px;
    font-size: 15px;
    direction: ltr;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Module-title {
    line-height: 37px;
    margin: 2px;
    font-size: 17px;
    direction: ltr;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ModuleAccordion-panel-buttons-div {
    flex-shrink: 0;
    margin-right: 13px;
}
.ModuleAccordion-buttons {
    float: right;
}

.Module-menu-icon {
    color: grey;
    width: 18px;
    height: 18px;
}

.Module-title-icon {
    width: 18px;
    height: 18px;
}
