.ErrorPage-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgb(246 249 252);
}


.ErrorPage-well {
    width: 100%;
    min-height: 400px;
    text-align: center;
    background-color: #fefefe !important;
    padding: 40px;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.ErrorPage-navbar {
    background-color: #E94E24;
}

.ErrorPage-container .Checkbox-label {
    font-weight: 400;
}

.ErrorPage-navbar {
    border-radius: 0 !important;
    min-height: 37px !important;
}
.ErrorPage-well {
    margin-top: 150px;
    padding: 24px;
    border-radius: 6px;
}

.ErrorPage-kpLogo {
    padding-left: 40px;
}
.ErrorPage-kpLogo-img {
    width: auto;
    height: 20px;
}

.remove-button-outline {
    outline: none !important;
}

.remove-button-outline:focus {
    box-shadow: none !important;
    outline: none !important;
}

.remove-button-outline:active {
    box-shadow: inset 0 0 5px #8080805c !important;
}
