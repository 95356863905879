.GlyphiconButton {
    display: flex !important;
    justify-content: center;
    font-size: 13px !important;
    border: none !important;
    color: rgb(63 64 71) !important;
    align-items: center;
    height: 30px;
    width: 30px;
    outline: none !important;
    margin: 0 !important;
    margin-left: 5px !important;
    vertical-align: middle;
    padding: 0 5px !important;
    border-radius: 50% !important;
}

.GlyphiconButton:hover {
    filter: brightness(95%);
    color: rgb(37, 37, 42) !important;
}

.GlyphiconButton > span {
    margin-left: 5px;
    margin-right: 5px;
}

.GlyphiconButton svg {
    vertical-align: top;
}
