.blueHeader {
    background-color: rgb(154, 204, 199) !important;
    position: sticky;
    top: 0;
    border-bottom: 1px solid #a7a4a4e3;
    border-top: 1px solid #a7a4a4e3;
}

.tableBoard {
    display: flex;
    justify-content: space-between;
    padding: 3px 1px;
    background: white;
    z-index: 1;
}

.tableTitle {
    align-items: center;
    display: inline-flex;
    font-weight: 400;
    font-size: 15px;
    color: rgb(104, 105, 114);
}
