.KpDataTable {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
}

.KpDataTable-Table {
    height: 100%;
    flex: 1 1 auto;
    padding-top: 5px;
}

.KpDataTableOverFlowHidden {
    overflow: hidden !important;
}


.kpDataTable-iconColumn {
    margin: 0 !important;
    padding: 0 !important;
}

.DragHandle {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    cursor: col-resize;
    color: #8a8a8a;
    margin-top: 5px;
    font-size: 18px;
    padding: 0 0.5rem;
}

.DragHandle:hover,
.DragHandleActive {
    color: #f6a;
}
