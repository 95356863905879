.GroupModel {
    overflow: hidden;
}

.GroupModel .active p {
    color: rgb(33, 33, 33);
}

.GroupModel a {
    cursor: pointer;
    display: block;
    padding: 10px 15px 10px 37px;
    color: rgb(33 33 33 / 71%);
    font-size: 14px;
    text-decoration: none;
}
.GroupModel p {
    color: rgb(33 33 33 / 87%);
    font-size: 14px;
}


.GroupModel a:hover {
    color: #4a4a4a;
    text-decoration: none;
}

.GroupModel a:focus {
    color: #4a4a4a;
    text-decoration: none;
}
.GroupModel a:active {
    color: #4a4a4a;
    text-decoration: none;
}

.GroupModel-category {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px 10px 10px;
    margin-bottom: 0;
    font-size: 14px;
    color: #4a4a4a;
    text-decoration: none;
}

.GroupModel-category-arrow-icon {
    color: rgba(33, 33, 33, 0.62);
    height: 17px;
    width: 17px;
}

.GroupModel-category-arrow-icon-active {
    color: rgb(33, 33, 33);;
    height: 17px;
    width: 17px;
}

.GroupModel-category span {
    padding-right: 5px;
}

.GroupModel-moduleList {
    padding-left:0;
    margin-bottom: 0;
}

.GroupModel-moduleList-li {
    margin: 1px 3px;
    border-radius: 5px;
    list-style-type: none;
}

.GroupModel-moduleList-li.active {
    background: #eff2f5;
}

.GroupModel-moduleList-li.active a {
    color: #e94d24;
}

.GroupModel-moduleList-li:hover {
    background: #eff2f5;
}

.GroupModel-category-icon {
    height: 17px;
    width: 17px;
    vertical-align: text-bottom;
    margin-bottom: 1px;
    margin-right: 10px;
}
