.ColorPicker-input {
    width: 100px;
    height: 50px;
    color: rgb(72 72 72);
    border: 1px solid #ccc;
    padding: 15px;
}

.sketch-picker {
    position: absolute;
    top: 0;
    z-index: 99;
}

.colorPicker-display {
    height: 33px;
    width: 33px;
    position: absolute;
    margin: 6px;
    z-index: 1;
    right: 0;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2)
}

.colorPicker-display:hover {
    border-color: rgb(0 0 0 / 50%);
}
