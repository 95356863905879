.RadioButtonField-question-label {
    font-size: 15px;
    margin-bottom: 5px;
}

.RadioButtonField-question-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}

.RadioButtonField-wrapper {
    margin-top: 15px;
    margin-left: 20px;
}

.RadioButton-label {
    padding-left: 15px;
    font-weight: 400;
}

.RadioButton {
    margin: 0;
    cursor: pointer;
    align-items: center;
}


.RadioButton:after {
    content: '';
    display: table;
    clear: both;
}

.RadioButton .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: 1.25em;
    width: 1.2em;
    height: 1.2em;
    float: left;
}

.RadioButton .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 12%;
    color: #607d8b;
}

.RadioButton input[type="radio"] {
    display: none;
}

.RadioButton input[type="radio"]+.cr>.cr-icon {
    opacity: 0;
}

.RadioButton input[type="radio"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.RadioButton input[type="radio"]:disabled+.cr {
    opacity: .5;
}

@media only screen
and (max-device-width: 600px) {
    .RadioButtonField-textarea {
        font-size: 16px !important;
    }
}

.RadioButtonField-textarea-container {
    position: relative;
    padding: 5px;
}

.RadioButtonField-textarea {
    width: 100%;
    padding: 12px !important;
}

.RadioButtonField-textarea:focus {
    outline: #015ECC !important;
    box-shadow: none !important;

}

.RadioButtonField-textarea:hover {
    border-color: rgb(0 0 0 / 50%);

}
