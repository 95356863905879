.Checkbox {}

.Checkbox-label {
    padding-left: 10px;
    color: rgb(52, 71, 103);
    font-weight: 400;
}

.Checkbox-input {
    color: rgb(52, 71, 103);
}

.Checkbox label {
    margin: 0;
    padding: 5px;
    cursor: pointer;
    align-items: center;
}

.Checkbox label:after {
    content: '';
    display: table;
    clear: both;
}

.Checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.2em;
    height: 1.2em;
    float: left;
}

.Checkbox .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 12%;
    color: rgb(52, 71, 103);
}

.Checkbox label input[type="checkbox"] {
    display: none;
}

.Checkbox label input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
}

.Checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.Checkbox label input[type="checkbox"]:disabled+.cr {
    opacity: .5;
}

.Form-checkbox{

}
