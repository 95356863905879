/* this class applies to all rows, including the heading */
.DualBox-Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin-bottom: 3%;
}

.BoxList-container {
    width: 40%;
    min-width: 333px;
    padding: 5px !important;
}

.BoxList-label {
    position: absolute;
    z-index: 1;
    top: 49px;
    background-color: white;
    padding: 0 5px;
    color: #686972;
    margin-left: 14px;
    font-weight: 400;
    font-size: 12px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.DualBox-Scrollbox {
    align-items: center;
    flex: 1;
}

.DualBoxBoardButton {
    height: 35px;
    width: 35px;
    margin: 0 3px 0 3px;
    color: #5b5965;
    bottom: 0;
    top: auto;
    outline: none !important;
    background-color: #fffafa !important;
    border-radius:25px !important;
    margin-left:5px !important;
    padding: 8px !important;
}

.DualBoxButtonDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    flex-wrap: wrap;
}
