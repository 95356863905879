.StyledInput-group {
    position: relative;
    padding: 5px 5px;
}

.StyledInput-input {
    width: 100%;
    height: 50px;
    color: rgb(72 72 72);
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 15px;
}

.StyledInput-label {
    background-color: white;
    color: #686972;
    position: absolute;
    font-weight: 400;
    padding: 0 5px;
    top: -3px;
    font-size: 11px;
    left: 15px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: all 200ms;
}

.StyledInput-input:hover {
    border-color: rgb(0 0 0 / 50%);
}

.StyledInput-input.hovered {
    border-color: rgb(0 0 0 / 50%);
}
