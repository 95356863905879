.LongCard-container {
    padding: 0 !important;
}

.LongCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
    padding: 5px 10px;
    margin: 10px 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
}

.LongCard:hover {
    box-shadow: 0 5px 9px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.LongCard h4 {
    text-align: center;
    font-weight: 400;
}

.LongCard-content {
    padding: 10px 20px;
    text-align: center;
}

.LongCard-link {
    height: 30px;
    position: relative;
    padding: 5px 10px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: right;

}

.LongCard-link a {
    color: #555;
}

.LongCard-link a:hover {
    color: #e84e23;
    text-decoration: none;
}

.SLongCard-icon {
    position: absolute;
    top: 3px;
}