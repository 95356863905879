@media only screen and (max-width : 812px) {
    .imgs .carousel-root {
        display: block; /* playlist under */
    }
    .imgs .carousel {
        width: 100%;
    }
    .imgs .thumbs-wrapper.axis-vertical {
        margin: 0; /* playlist margin */
        overflow: auto;
        border: 1px solid #E0E0E0;
    }
}
@media only screen and (min-width : 813px) {
    .imgs .carousel-root {
        display: flex; /* playlist on the rigt */
    }
    .imgs .carousel {
        flex: 1;
        width: 25%;
    }
    .imgs .thumbs-wrapper.axis-vertical {
        margin: 0; /* playlist margin */
        overflow: auto;
        border: 1px solid #E0E0E0;
        margin-left: 5%;
        margin-bottom: 5%;
    }
}

/* Images */
.imgs .carousel.carousel-slider {
    flex: 2; /* image size */
}

.imgs .carousel .slide {
    background: black;
}

.imgs .imageItem p {
    margin: 0;
    border-bottom: 1px solid #E0E0E0;
    word-break: break-word;
    font-style: italic;
}


/* Playlist */

/** Vertical scrollbar **/
.imgs .thumbs-wrapper.axis-vertical::-webkit-scrollbar {
    -webkit-appearance: none;
}
.imgs .thumbs-wrapper.axis-vertical::-webkit-scrollbar:vertical {
    width: 11px;
}
.imgs .thumbs-wrapper.axis-vertical::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .4);
}

.imgs .thumbs-wrapper.axis-vertical ul {
    padding-left: 0; /* playlist padding */
    transform: none !important;
    transition-duration: 0s !important;
}

.imgs .carousel button {
    opacity: 0.7 !important;
    padding: 10px !important;
}
.imgs .carousel .thumb {
    display: flex; /* thumbnails vertical */
    width: 100% !important; /* thumbnails image fix */
    transition: none;
}
.imgs .carousel .thumb.selected, .imgs .carousel .thumb:hover {
    border: 3px solid #fff; /* imageThumb vertical spacing */
    background-color: #ECECEC;
}

.imgs .imageThumbnail {
    display: flex; /* thumbnails text on the right */
}
.imgs .imageThumbnail img {
    flex: 1; /* thumbnails image size relative to text */
    align-self: flex-start;
    width: 25%;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
}
.imgs .imageThumbnail p {
    flex: 2; /* thumbnails text size */
    margin: 0;
    white-space: normal;
    word-break: break-word;
    font-size: small;
    margin-left: 5px;
    margin-top: 3%;
    margin-bottom: 3%;
}
