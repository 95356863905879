.DataTable-InputCell {
    width: 95%;
    height: 24px;
    border: 1px solid #939bb4;
    border-radius: 4px;
    outline: none;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
}

.DataTable-InputCell:focus {
    width: 100%;
    border: 1px solid #b4452d;
}
