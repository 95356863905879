.FilterTextField-wrapper {
    width: 330px !important;
    display: flex;
    flex-direction: row;
}

.FilterTextField-input {
    margin:5px 5px 0 0;
    outline: none !important;
}

.FilterTextField-button {
    margin-top: 4px;
}