.TextField-input {
    width: 100%;
    height: 50px;
    color: rgb(72 72 72);
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 15px;
}

.TextField-label {
    font-size: 15px;
    margin-bottom: 10px;
}

.TextField-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}

.TextField-input:hover {
    border-color: rgb(0 0 0 / 50%);
}

.TextField-input.hovered {
    border-color: rgb(0 0 0 / 50%);
}
