.Title-container {
    margin-bottom: 5px;
}

.Title-label {
}

.Title-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}

.Title1 {
    font-size: 20px;
    color: rgb(225, 101, 0);
}

.Title2 {
    font-size: 18px;
    color: rgb(39, 94, 143);
}

.Title3 {
    font-size: 16px;
    color: rgb(39, 94, 143);
}


