.PrivateApp-container {
    background: #eff2f5;
}

.PrivateApp-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden !important;
    background-color: rgb(239 242 245);
}

.PrivateApp-sidebar {
    overflow-y: visible !important;
    box-shadow: none !important;
}
