.NotificationBubble {
    width: 20px;
    height: 20px;
    padding: 0 !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    color: #f84a20;
    justify-content: end;
    margin-right: 5px;
}
