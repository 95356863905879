.ShortCard-container {
    padding: 0 !important;
}
.ShortCard {
    height: 300px;
    margin: 10px 10px;
    padding: 5px 10px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
}

.ShortCard:hover {
    box-shadow: 0 5px 9px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.ShortCard h4 {
    font-weight: 400;
    font-size: 20px;
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 5px;
}

.ShortCard-dates {
    font-size: 11px;
    text-align: center;
    color: #888383;
}

.ShortCard-content {
    color: #eb4d11;
    padding: 8px 20px;
    text-align: center;
    font-size: 30px;
}

.ShortCard-link {
    height: 30px;
    position: relative;
    padding: 5px 10px;
    margin-right: 16px;
    margin-bottom: 8px;
    text-align: right;

}

.ShortCard-link a {
    color: #4a4a4a;
}

.ShortCard-link a:hover {
    color: #e84e23;
    text-decoration: none;
}

.ShortCard-icon {
    position: absolute;
    top: 3px;
}
