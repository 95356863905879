.ChartContainer{
    padding: 0 !important;
}

.ChartCard {
    height: 300px;
    margin: 10px 10px;
    padding: 5px 0;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
}

.ChartCard:hover {
    box-shadow: 0 5px 9px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.ChartCard-content {
    height: 500px;
    width: 100%;
    padding: 10px 10px;
    text-align: center;
    font-size: inherit;
}

.ChartCard-title {
    font-weight: 400;
    font-size: 20px;
    color: #4a4a4a;
    text-align: center;
    padding: 10px 20px;
}


.ChartCard h4 {
    font-weight: 400;
    font-size: 20px;
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 5px;
}

.ChartCard-dates {
    font-size: 11px;
    text-align: center;
    color: #888383;
}

.ChartCard-subTitle {
    font-size: 13px;
    text-align: center;
    color: #4a4a4a;
}

.ChartCard-total {
    font-size: 22px;
    text-align: center;
}
