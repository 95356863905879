.bigBox {
    border: 1px solid #e2e2e2;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    height: 34px;
    margin-left: 5px;
    padding: 3px 6px;
    max-width: fit-content;
}




.toggleButton {
    padding: 1px 14px;
    font-size: 14px;
    cursor: pointer ;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #666666;
}

.toggleButton.selected {
    border-color: #1e74d9;
    border-width: 1px;
    background-color: white;
    border-radius: 4px;
    color: #1e74d9;
    transition: 0.3s;
    box-shadow: 0 0 0px #1e74d9 inset;
}

.toggleButton.disabledButton {
    opacity: 0.5;
    cursor:not-allowed !important;
}






.buttonNotSelectedDisabled-true {
    border: 1px solid rgba(0, 0, 0, 0);
    color: rgba(102, 102, 102, 0.7);
    cursor:not-allowed !important;
    font-size: large;
}








/*
.bigBoxStyle2 {
    display: inline-grid;
    height: 24px;
}

.buttonSelectedStyle2 {
    border-color: orangered;
    border-width: 1px;
    background-color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: orangered;
    cursor:pointer;
    box-shadow: 0px 0px 3px orangered inset;
}
.buttonSelectedStyle2 {
    border-color: orangered;
    border-width: 1px;
    background-color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: orangered;
    cursor:pointer;
    box-shadow: 0px 0px 3px orangered inset;
}

.buttonNotSelectedStyle2 {
    border: none;
    background-color: #f2f2f2;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-width: 1px;
    color: #666666;
    cursor:pointer;
}
*/



