.PhoneNumberInput-group {
    position: relative;
    padding: 5px 5px;
}

.PhoneNumberInput-input {
    width: 100% !important;
    height: 50px !important;
    color: rgb(72 72 72) !important;
    border-radius: 4px !important;
    border: 1px solid #ccc !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
}

.PhoneNumberInput-label {
    background-color: white;
    color: #686972;
    position: absolute;
    font-weight: 400;
    padding: 0 5px;
    top: -3px;
    z-index: 1;
    font-size: 11px;
    left: 15px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: all 200ms;
}
