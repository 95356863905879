.DatePicker-container {
    margin: 1px 15px 0 0;
    width: 260px;
}

.DatePicker-calendar {
    width: 260px;
    padding: 3px;
    border-radius: 6px;
}

.DatePicker{
    width: 250px;
    height: 36px;
}

.DatePicker-span {
    top: 0 !important;
}

.DatePicker-input {
    position: static !important;
    height: 36px !important;
    box-shadow: none !important;
}

@media only screen
and (max-device-width: 600px) {
    .DatePicker-input {
        font-size: 16px !important;
    }
    .rc-calendar-input {
        font-size: 14px;
    }
}
