@media only screen and (max-width : 812px) {
    .vids .carousel-root {
        display: block; /* playlist under */
    }
    .vids .carousel {
        width: 100%;
    }
    .vids .thumbs-wrapper.axis-vertical {
        margin: 0; /* playlist margin */
        overflow: auto;
        border: 1px solid #E0E0E0;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}
@media only screen and (min-width : 813px) {
    .vids .carousel-root {
        display: flex; /* playlist on the rigt */
    }
    .vids .carousel {
        flex: 1;
        width: 25%;
    }
    .vids .thumbs-wrapper.axis-vertical {
        margin: 0; /* playlist margin */
        overflow: auto;
        border: 1px solid #E0E0E0;
        margin-left: 5%;
        margin-bottom: 5%;
    }
}

/* Videos */
.vids .carousel.carousel-slider {
    flex: 2; /* video size */
}

.vids .carousel .slider.animated {
    transition: none; /* sliding transition for videos */
    transition-duration: 0s !important;
}
.vids .carousel .slide {
    background: white;
}

.vids .videoItem p {
    margin: 0;
    border-bottom: 1px solid #E0E0E0;
    word-break: break-word;
}


/* Playlist */

/** Vertical scrollbar **/
.vids .thumbs-wrapper.axis-vertical::-webkit-scrollbar {
    -webkit-appearance: none;
}
.vids .thumbs-wrapper.axis-vertical::-webkit-scrollbar:vertical {
    width: 11px;
}
.vids .thumbs-wrapper.axis-vertical::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .4);
}

.vids .thumbs-wrapper.axis-vertical ul {
    padding-left: 0; /* playlist padding */
    transform: none !important;
    transition-duration: 0s !important;
}

.vids .carousel button {
    visibility: hidden;
}
.vids .carousel .thumb {
    display: flex; /* thumbnails vertical */
    width: 100% !important; /* thumbnails image fix */
    transition: none;
}
.vids .carousel .thumb.selected, .vids .carousel .thumb:hover {
    border: 3px solid #fff; /* videoThumb vertical spacing */
    background-color: #ECECEC;
}

.vids .videoThumbnail {
    display: flex; /* thumbnails text on the right */
}
.vids .react-thumbnail-generator {
    flex: 1; /* thumbnails image size */
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
}
.vids .videoThumbnail p {
    flex: 2; /* thumbnails text size */
    margin: 0;
    white-space: normal;
    word-break: break-word;
    font-size: small;
    margin-left: 5px;
    margin-top: 3%;
    margin-bottom: 3%;
}

.vids canvas.snapshot-generator {
    width: 0 !important;
}
.vids video.snapshot-generator {
    width: 80px;
}