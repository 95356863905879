input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.FilterNumberField-wrapper {
    width: 330px !important;
    display: flex;
    flex-direction: row;
}

.FilterNumberField-input {
    margin:5px 5px 0 0;
    outline: none !important;
    box-shadow: none !important;
}

.FilterNumberField-input:focus {
    outline: none !important;
    border-color: #ccc !important;
}
