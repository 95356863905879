@media only screen
and (max-device-width: 600px) {
    .Select {
        font-size: 16px;
    }
}


.Creatable {
    position: relative;
}

.Creatable-label {
    background-color: white;
    position: absolute;
    z-index: 1;
    font-weight: 400;
    padding: 0 5px;
    top: -8px;
    left: 15px;
    font-size: 11px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.Select-Container {
    margin-top: 1px;
    margin-bottom: 5px;
    padding: 0 5px !important;
}

.Select-label {
    background-color: white;
    color: #686972;
    position: absolute;
    z-index: 1;
    font-weight: 400;
    padding: 0 5px;
    top: -2px;
    left: 15px;
    font-size: 11px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: all 200ms;
}

.Select-separate-label {
    font-size: 15px;
    margin-bottom: 10px;
}

.Select-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}

.Select-label.isFocused {
    top: -1px;
    font-size: 11px;
    transition: all 100ms;
}
