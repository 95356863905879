.DtObjectsComponent-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-right: 2px;
}

.DtObjectsComponent-container-withBoard {
    border-top: 1px solid #ddd;
    padding-right: 2px;
}

.DtObjectsComponent-label {
    position: absolute;
    font-weight: 400;
    margin-left: 10px;
    color: #686972;
    z-index: 1;
    padding: 0 5px;
    background-color: white;
    top: -2px;
    font-size: 11px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.DtObjectsComponent-Board {
    background: #ffffff;
    padding: 8px 5px 6px;
    display: flex;
    justify-content: space-between;
}

.DtObjectsComponent-Board-left {
    display: flex;
}

.DtObjectsComponent-Board-right {
    display: flex;
}


.BlueColumns {
    height: 100%;
    width: 100%;
    background-color: #d7eaff;
}

.breakHeaderLine {
    word-spacing: 1000px;
    white-space: normal !important;
}

.DtObjectsComponent-Link {
    color: #116cdc;
}

.DtObjectsComponent-Link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.DtObjectsComponent-Link-separator {
    margin: 0 5px;
}


.DataTable-Table-parent-headerRow {
    color: #686972 !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    font-weight: 700;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
}
