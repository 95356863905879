.MultiCheckboxField-wrapper {
    margin-top: 15px;
    margin-left: 20px;
}

.MultiCheckboxField-label {
    font-size: 15px;
    margin-bottom: 5px;
}

.MultiCheckboxField-subtitle {
    font-style: italic;
    margin-left: 5px;
    font-size: 12px;
    color: #676767;
}
.MultiCheckboxField-input {
}

.MultiCheckboxField-option {
    padding-left: 15px;
    font-weight: 400;
}

.MultiCheckboxField-checkbox {
    margin: 0;
    cursor: pointer;
    align-items: center;
}


.MultiCheckboxField-checkbox:after {
    content: '';
    display: table;
    clear: both;
}

.MultiCheckboxField-checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.2em;
    height: 1.2em;
    float: left;
}

.MultiCheckboxField-checkbox .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 12%;
    color: #607d8b;
}
.MultiCheckboxField-textarea-container {
    position: relative;
    padding: 5px;
}

.MultiCheckboxField-checkbox input[type="checkbox"] {
    display: none;
}

.MultiCheckboxField-checkbox input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
}

.MultiCheckboxField-checkbox input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.MultiCheckboxField-checkbox input[type="checkbox"]:disabled+.cr {
    opacity: .5;
}
