.ProgressBar {
    position: relative;
    margin: 2px;
    height: 4px;
    width: 100%;
    background: white;
    border-radius: 50px;
}
.Filler {
    background: #1DA598;
    height: 100%;
    border-radius: inherit;
    transition: width .2S ease-in;
}
