nav {
    margin: 5px 15px 0 15px;
    min-height: 40px;
    border-radius: 0;
    display:flex !important;
    justify-content:space-between;
    align-items: center;
}
.horizontalLogo {
}
.horizontalLogo img {
    opacity:0.9;
}

.navbar-right {
    margin-right: 0 !important;
    cursor: pointer;
    display: flex;
}

.Navbar-Menu-list {
    min-width: 230px;
    max-height: 600px;
    padding: 5px;
    margin: 0;
    text-align: left;
    font-size: 14px;
    list-style: none;
    overflow: scroll;
}

.Navbar-Menu-list .divider {
    height: 1px;
    margin: 9px 0;
    padding: 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.Navbar-Menu-item {
    display: inline-flex;
    align-items: flex-end;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
}

/*
.Navbar-Menu-item:not(:last-child) {
    margin-bottom: 8px;
}
 */


.Navbar-notification {
    position: absolute;
    top: 10px;
    left: 23px;
    height: 8px;
    font-size: 9px;
    width: 8px;
    color: #e94d24;
    background: #e94d24;
    border-radius: 50%;
}

.Navbar-Menu-item:hover {
    background-color: rgb(240, 242, 245);
    cursor: pointer;
}

.Navbar-Menu-list a {
    text-decoration: none !important;
    color: rgb(52, 71, 103) !important;
}

.Navbar-Menu-item:hover > a {
    text-decoration: none;
    color: rgb(52, 71, 103);
}

.Navbar-Menu-item svg {
    margin-right: 10px;
    color: rgb(123, 128, 154);
}

.Navbar-Menu-item:hover > svg {
    color: rgb(52, 71, 103);
}

.selectedLanguage {
    font-weight: bolder !important;
}

.loggedUser {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    color: #4d4a5a;
    cursor: default;
}

.navbar-icon-container {
    position: relative;
    border: 0;
    border-radius: 50%;
    margin-left: 2px;
    display: inline-flex;
    background-color: transparent;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 10px;
}

.navbar-icon-container:hover {
    background-color: rgb(0 0 0 / 3%);
}

.navbar-icon {
    color: #62626e;
    height: 18px;
    width: 18px;
}

.navbar-hamburger-icon {
    color: #62626e;
    height: 20px;
    width: 19px;
}

.docsIcon {
    margin-top: 7px;
    margin-left: 9px;
}

.cogMenu {
    right: 1px;
    left:auto !important;
}

.Profile-menuIcon {
    color: #5b5965;
    margin-left: 6px;
    margin-top: 3px;
}

.Profile-menuLi {
    display: flex;
    padding: 0 2px;
}

.Profile-menuLi a {
    padding: 3px 10px 3px 15px !important;
}
