@media only screen
and (max-device-width: 600px) {
    .TextAreaField-textArea {
        font-size: 16px !important;
    }
}

.TextAreaField-container {
    position: relative;
    padding: 5px;
}

.TextAreaField-textArea:focus {
    outline: auto !important;
    outline-color: #015ECC !important;
    box-shadow: none !important;

}

.TextAreaField-textArea:hover {
    border-color: rgb(0 0 0 / 50%);

}

.TextAreaField-textArea {
    background: white !important;
    padding: 12px !important;
}

.TextAreaField-textArea.disabled {
    border-color: #e3e3e3;
}

.TextAreaField-label {
    background-color: white;
    color: #686972;
    position: absolute;
    font-weight: 400;
    padding: 0 5px;
    top: -3px;
    font-size: 11px;
    left: 15px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: all 200ms;
}
