.ColumnSelector-Checkbox {
    margin: 5px 0 5px 13px;
}

.dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
}

.ColumnSelector-dropDown {
    min-width: 250px !important;
    padding: 0 5px !important;
}

.ColumnSelector-hr {
    margin-top: 0;
    margin-bottom: 8px;
}