.DataTable-Board {
    display: flex;
    justify-content: space-between;
}

/*leftBoard Style*/
.DataTable-Board-left {
    display: flex;
}
/*rightBoard Style*/
.DataTable-Board-right {
    display: flex;
}

.DataTable-Board-icon-container {
    border: 0;
    border-radius: 50%;
    margin-left: 2px;
    display: inline-flex;
    background-color: transparent;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 10px;
}

.DataTable-Board-icon {
    color: #62626e;
    height: 18px;
    width: 18px;
}

.DataTable-Board-icon-container:hover {
    background-color: rgb(0 0 0 / 4%);
}
