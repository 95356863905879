.DataTableFilter {
    position: relative;
}

.DataTableFilter-icon {
    position: absolute;
    width: 20px;
    left:2px;
    right: 130px;
    margin-top: 10px;
    margin-left: 4px;
    color: #7b848c;
}

.DataTableFilter-input {
    border: 1px solid #dfdcdc;
    outline: none;
    width: 210px;
    height: 38px;
    font-size: 10pt;
    padding-left: 30px;
    border-radius: 5px;
}
