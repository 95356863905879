.SidebarContent {
    margin: 10px 0 7px 10px;
    background-color: white;
    width: 250px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgb(90 114 123 / 11%) 0 7px 30px 0 !important;
}

.SidebarContent-logo-container {
    height: 160px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SidebarContent-logo {
    max-height: 160px;
    max-width: 250px;
    padding: 5px;
    object-fit: contain;
}

.SidebarContent-logo img {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
}

.SidebarContent-modules {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* fixed height for firefox compatibility */
    height: calc(100% - 227px);
}

.SidebarContent-modules.fullLength {
    /* fixed height for firefox compatibility */
    height: calc(100% - 160px);
    border-top: 1px solid #ccc3c3;
}

.SidebarContent-groupModules {
    overflow: auto;
}

.SidebarContent-dropDown {
    padding: 8px 16px 8px 16px;
    height: 67px;
}

.technicalMenu {
    border-top: solid 1px #ccc3c3;
    margin-top: 5px;
}

.technicalMenu-messaging {
    position: relative;
}

.technicalMenu-messaging-title {
    margin: 0
}

.technicalMenu-messaging-list {
    position: absolute !important;
    left: 100%;
    bottom: 0;
    width: 200px;
    padding-left:0;
    margin-bottom: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc3c3;
    margin-left: 4px;
    border-radius: 5px;
}

.technicalMenu-messaging-list-li {
    list-style-type: none;
}


.technicalMenu a, .technicalMenu p {
    cursor: pointer;
    display: block;
    padding: 8px 8px 8px 8px;
    color: rgb(33 33 33 / 87%);
    font-size: 13px;
    text-decoration: none;
    margin: 1px;
    border-radius: 10px;
}

.technicalMenu .active p {
    color: #4a4a4a;
    background-color: rgb(232 78 35 / 20%);
}

.technicalMenu-icon {
    height: 18px;
    width: 18px;
    vertical-align: text-bottom;
    margin-bottom: 1px;
    margin-right: 10px;
}

.SidebarContent-returnButton {
    margin-top: 20px;
    text-align: center;
}

.SidebarContent-returnButton a {
    display: block;
    margin-top: 20px;
    padding: 15px 10px 15px 10px;
    cursor: pointer;
    text-decoration: none;
}

.SidebarContent-returnButton a:hover {
    background-color: #efeeee;
    text-decoration: none;
}
