.Cursor-container {
    margin: 15px 0 0 20px;
    display: flex;
    flex-direction: row;
    height: 38px;
    border-left: 1px solid #d5d5d5;
}

.Cursor-Button {
    padding: 1px 15px;
    background: white;
    font-size: 16px;
    border-top: 1px solid #80808054;
    border-bottom: 1px solid #80808054;
    border-right: 1px solid #80808054;
    border-left: none;
    color: #666666;
}

.Cursor-Button.selected {
    background: #4e8fdb !important;
    color: white !important;
    border-color: #437cc0 !important;
    border-left: 1px solid #437cc0;
    transition: 0.3s;
}
